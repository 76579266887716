<template>
  <div class="FooterShop">

    <template v-if="context == 'boutique'">

    <div class="FooterShop--boutique-container">

      <div class="FooterShop--boutique-container--links">
        <a :href="$site.links.newsletter" target="_blank">Newsletter</a>
        <a :href="$site.links.ig" target="_blank">Instagram</a><br>
        <a :href="$site.links.fb" target="_blank">Facebook</a><br>
        <a :href="$site.links.yt" target="_blank">Youtube</a>
      </div>

      <router-link class="FooterShop--boutique-container--title"
      v-bind:to=" '/' + $route.params.language + '/'">
        <p v-if="$route.params.language == 'fr'">Retour vers la revue</p>
        <p v-if="$route.params.language == 'en'">Back to homepage</p>
      </router-link>

      <div class="FooterShop--boutique-container--info"></div>
      
    </div>

    </template> 

    <template v-if="context == 'product'">

      <div class="FooterShop--product-container">

        <div class="FooterShop--product-container--links">
          <a :href="$site.links.newsletter" target="_blank">Newsletter</a>
          <a :href="$site.links.ig" target="_blank">Instagram</a><br>
          <a :href="$site.links.fb" target="_blank">Facebook</a><br>
          <a :href="$site.links.yt" target="_blank">Youtube</a>
        </div>

        <router-link class="FooterShop--product-container--back"
        v-bind:to=" '/' + $route.params.language + '/boutique'">
           <p v-if="$route.params.language == 'fr'">Retour vers la collection</p>
           <p v-if="$route.params.language == 'en'">Browse the store</p>
        </router-link>

      </div>

    </template> 

  </div>
</template>

<script>
export default {
  name: 'FooterShop',
  props: ['content', 'context'],
  computed:{

  },
  methods: {

  },
  created(){
    // console.log(this.context)
  }
  
}
</script>

<style lang="scss">
@import '@/css/variables';

.FooterShop{
	color: white;
  width: 100%;
  // height: 30vw;
  background-color: black;
  text-align: center;

  display: flex;
  flex-direction: column;
  justify-content: center;



  

  &--boutique-container{
    
    color: white;
    width: 100% ;
    // height: 50vw;
    background-color: black;



    &--links{
      padding: var(--esp-big);
      display: flex;
      width: calc(100% - 2 * var(--esp-big)) ;
      justify-content: space-between;
      margin-bottom: var(--esp-big);
      font-size: var(--shop-font-labeur-subtitle);

      // background-color: rgb(40,40,40);

      & a {
        color: white;
        width: 25%;
        text-align: center;
      }

    }

    &--title{
      font-size: 19vw;
      line-height: 0.85;
      margin-top: var(--esp-big);
      // margin-bottom: var(--esp-big);

      & p {
        color: white;
        margin-bottom: var(--esp-big);
        // line-height: 1.1;
        margin-left: var(--esp-big);
        margin-right: var(--esp-big);
      }
    }
  }



  &--product-container{

    &--links{
      padding: var(--esp-big);
      display: flex;
      width: calc(100% - 2 * var(--esp-big)) ;
      justify-content: space-between;
      margin-bottom: var(--esp-big);
      font-size: var(--shop-font-labeur-subtitle);

      // background-color: rgb(40,40,40);

      & a {
        color: white;
        width: 25%;
        text-align: center;

      }

    }

    &--back{
      font-size: 19vw;
      line-height: 0.85;
      margin-top: var(--esp-big);
      // margin-bottom: var(--esp-big);

      & p {
        color: white;
        margin-bottom: var(--esp-big);
        // line-height: 1.1;
        margin-left: var(--esp-big);
        margin-right: var(--esp-big);
      }
    }

}

}


</style>