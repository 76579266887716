<template>
  <div class="ViewerShopSlide">

    <div 
    class="ViewerShopSlide--slider"
    ref="slider"
    :style="{transform: 'translate('+ whatTranslate +'vw)', width: whatWidth}"
    >
    <!-- :style="{transform: 'translate('+ whatTranslate +'%)'}" -->

<!--       <img 
      class="ViewerShopSlide--slider--img"
      :class="[whatConfig]"
      v-lazyloading
      :src="content[content.length - 1].thumb"
      :data-srcset="content[content.length - 1].srcset"
      :sizes=" context == 'mobile' ? '100vw' : '50vw'">

      <img 
      class="ViewerShopSlide--slider--img"
      :class="[whatConfig]"
      v-for="(image, index) in content" 
      :key="index" 
      v-lazyloading
      :src="image.thumb"
      :data-srcset="image.srcset"
      :sizes=" context == 'mobile' ? '100vw' : '50vw'">

      <img 
      class="ViewerShopSlide--slider--img"
      :class="[whatConfig]"
      v-lazyloading
      :src="content[0].thumb"
      :data-srcset="content[0].srcset"
      :sizes=" context == 'mobile' ? '100vw' : '50vw'"> -->


      <img 
      class="ViewerShopSlide--slider--img"
      :class="[whatConfig]"
      :src="content[content.length - 1].thumb"
      :srcset="content[content.length - 1].srcset"
      :sizes=" context == 'mobile' ? '100vw' : '50vw'">

      <img 
      class="ViewerShopSlide--slider--img"
      :class="[whatConfig]"
      v-for="(image, index) in content" 
      :key="index" 
      :src="image.thumb"
      :srcset="image.srcset"
      :sizes=" context == 'mobile' ? '100vw' : '50vw'">

      <img 
      class="ViewerShopSlide--slider--img"
      :class="[whatConfig]"
      :src="content[0].thumb"
      :srcset="content[0].srcset"
      :sizes=" context == 'mobile' ? '100vw' : '50vw'">

    </div>

  </div>
</template>

<script>
import LazyLoading from '@/directives/LazyLoading';

export default {
  name: 'ViewerShopSlide',
  directives:{
    LazyLoading
  },
  props: ['content', 'parentIndex', 'context'],
  data: function(){
    return {
      index: 2,
      controlIndex: this.parentIndex
    }
  },
  watch:{
    parentIndex: function(newValue,oldvalue){
      let diffValue = oldvalue - newValue > 0 ? -1 : 1

      if (this.index == (this.content.length + 1) && diffValue > 0){ // max
        this.restartSlider()

      }else if (this.index == 1 && diffValue < 0) { // min
        this.reverseSlider()

      }else{
        this.index += diffValue
      }
    }
  },
  computed:{
    whatTranslate: function(){
      if (this.context == 'mobile'){
        return -1 * this.index * 100 + 100;
      }else {
        return -1 * this.index * 50 + 50;
      }
    },
    whatConfig: function(){
      if (this.context == 'mobile'){
        return 'viewerShopSlide-mobile'
      }else {
        return ''
      }
    },
    whatWidth: function(){
      return (this.content.length + 5) * 100 + '%'
    }
  },
  methods: {

    restartSlider: function(){

      this.$refs.slider.style.transition = 'transform 0s ease'; 
      this.index = 1

      setTimeout(() => { 
        this.$refs.slider.style.transition = 'transform 0.5s ease'; 
        this.index = 2
      }, 20);
    },

    reverseSlider: function(){

      this.$refs.slider.style.transition = 'transform 0s ease'; 
      this.index = this.content.length + 1

      setTimeout(() => { 
        this.$refs.slider.style.transition = 'transform 0.5s ease'; 
        this.index = this.content.length
      }, 20);
    },

    loadImgs: function(){
      this.content.forEach(element => {
        let img = new Image();
        img.onload = function() {
          // console.log('IMG LOADED')
        }
        img.srcset = element.srcset
      })
    }
  },
  mounted(){
    // this.loadImgs()
    

    // console.log(this.content)
  },
  beforeDestroy(){
    // console.log('viewerShopSlide destroy')
  }
  
}
</script>

<style lang="scss">

.ViewerShopSlide{
  height: 100%;
  width: 100%;

  &--slider{
    display: flex;
    height: 100%;
    // width: 3000%;

    transition: transform 0.5s ease;
    // transform: translate(-2%);
    

    &--img{
      width: 50vw;
      height: 100%;
      object-fit: cover;
    }  
  }
}


.ViewerShopSlide--slider--img.viewerShopSlide-mobile{
   width: 100vw;
   height: 100%;
   object-fit: cover;

}


</style>