<template>
  <div class="ViewerShop">

    <div class="ViewerShop--click-area">
      <div class="ViewerShop--click-area--left" :class="whatComputConfig" @click="setIndex(-1)"></div>
      <div class="ViewerShop--click-area--right" :class="whatComputConfig" @click="setIndex(1)"></div>
    </div>

    <div class="ViewerShop--container" >

      <template v-if="whatConfig != '-mobile'">

        <div class="ViewerShop--container--left">
          <ViewerShopSlide50 :content="whatContent('odd')" :parentIndex="index"></ViewerShopSlide50>
        </div>

        <div class="ViewerShop--container--right">
          <ViewerShopSlide50 :content="whatContent('even')" :parentIndex="index"></ViewerShopSlide50>
        </div>

      </template>

       <template v-else>

        <div class="ViewerShop--container--mobile">
          <!-- <ViewerShopSlide :content="content" :parentIndex="index" :context="'mobile'"></ViewerShopSlide> -->
          <ViewerShopSlide100 :content="whatContent('mobile')" :parentIndex="index" :context="'mobile'"></ViewerShopSlide100>
        </div>

        <div class="ViewerShop--container--mobile--arrows">
          <div class="ViewerShop--container--mobile--arrows--left">
            <img class="ViewerShop--container--mobile--arrows--left--img" src="@/assets/icone/arrow_left-min.svg">
          </div>
          <div class="ViewerShop--container--mobile--arrows--right">
            <img class="ViewerShop--container--mobile--arrows--right--img" src="@/assets/icone/arrow_right-min.svg">
          </div>
        </div>

      </template>

    </div>
  </div>
</template>

<script>
import ViewerShopSlide50 from '@/components/boutique/ViewerShopSlide50.vue'
import ViewerShopSlide100 from '@/components/boutique/ViewerShopSlide100.vue'
import clientConfigs from '@/mixins/clientConfigs.js'

export default {
  name: 'ViewerShop',
  mixins: [clientConfigs],
  props: ['content'],
  data: function(){
    return {
      index: 0
    }
  },
  components: {
    ViewerShopSlide50,
    ViewerShopSlide100
  },
  computed:{
    isMobileTemplate: function(){
      console.log(this.ClientDevice, this.ClientFormat)
      if ( this.ClientDevice != 'desktop' || this.ClientFormat == 'portrait'){
        return true
      }else {
        return false
      }
    },

    whatConfig: function(){

      if (this.ClientFormat == 'portrait'){

        if (this.ClientWidth == 'mobileWidth'){
          return '-mobile'
        }else {
          return '-portrait'
        }
        
      }else {
        return ''
      }
    },
  },
  methods: {
    // whatContent: function(type){

    //   let oddArray = [];
    //   let evenArray = [];
      
    //   this.content.forEach((element, index) => {
    //     if (index % 2 === 0){
    //       evenArray.push(element)
    //     }else{
    //       oddArray.push(element)
    //     }
    //   })

    //   if (type === 'even'){
    //     return evenArray
    //   }else{
    //     return oddArray
    //   }
    // },
    
    whatContent: function(type){

      // console.log('viewershop whatcontent', type)

      if (type == 'mobile'){

        return this.content

      }else {

        let oddArray = [];
        let evenArray = [];
        
        this.content.forEach((element, index) => {
          if (index % 2 === 0){
            evenArray.push(element)
          }else{
            oddArray.push(element)
          }
        })

        if (type === 'even'){
          return evenArray
        }else{
          return oddArray
        }

      }


    },
    setIndex: function(direction){
      this.index = this.index + direction
    }
  },
  mounted(){
    // console.log(this.content)
  }
  
}
</script>

<style lang="scss">

.ViewerShop{
  position: relative;
  width: 100%;
  height: 100%;

  &--container{
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: space-between;

    background-color: black;

    &--left{
      height: 100%;
      width: 49.99%;

      overflow: hidden;
    }

    &--right{
      height: 100%;
      width: 49.99%;

      overflow: hidden;
    }
  }
}

.ViewerShop--click-area{
  position: absolute;

  width: 100%;
  height: 100%;

  z-index: 180;

  display: flex;

  &--left{
    width: 50%;
    height: 100%;
    cursor: var(--cursor-arrow-left);

    &.-mobile{
      cursor: pointer;
    }
  }

  &--right{
    width: 50%;
    height: 100%;
    cursor: var(--cursor-arrow-right);

    &.-mobile{
      cursor: pointer;
    }
  }
}

.ViewerShop--container--mobile{
  height: 100%;
  width: 100%;
}

.ViewerShop--container--mobile--arrows{
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;

  pointer-events: none;

  &--left{
    width: 50%;

    // background-color: red;
    opacity: 1;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;   

    &--img{
      padding-left: var(--esp-med);

    }

  }

  &--right{
    width: 50%;
    // background-color: green;
    opacity: 1;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;  

    &--img{
      padding-right: var(--esp-med);
    }
  }
}

</style>