<template>
  <div class="HeaderShop">

    <div class="HeaderShop--main">

      <div class="HeaderShop--main--subtitle" @mouseenter="mouseEnter('subtitle')" @mouseleave="mouseLeave('subtitle')">
        <router-link v-bind:to=" '/' + $route.params.language + '/'">

          <p v-if="!subtitleIsActive && !titleIsActive">Profane</p>
          <p v-if="subtitleIsActive && !titleIsActive && $route.params.language == 'fr'">Retour vers la revue</p>
          <p v-if="subtitleIsActive && !titleIsActive && $route.params.language == 'en'">Back to homepage</p>
        </router-link>
      </div>

      <template v-if="context == 'product' ? true : false">
        <div class="HeaderShop--main--title" @mouseenter="mouseEnter('title')" @mouseleave="mouseLeave('title')">
          <router-link v-bind:to=" '/' + $route.params.language + '/boutique'">
          <p v-if="!titleIsActive && !subtitleIsActive && $route.params.language == 'fr'">Boutique</p>
          <p v-if="!titleIsActive && !subtitleIsActive && $route.params.language == 'en'">Shop</p>
          <p v-if="titleIsActive && !subtitleIsActive && $route.params.language == 'fr'">Retour vers la collection</p>
          <p v-if="titleIsActive && !subtitleIsActive && $route.params.language == 'en'">Back to collection</p>
          </router-link>
        </div>
      </template>

      <template v-else>
        <div class="HeaderShop--main--title">
          <div>
            <p v-if="!titleIsActive && !subtitleIsActive && $route.params.language == 'fr'">Boutique</p>
            <p v-if="!titleIsActive && !subtitleIsActive && $route.params.language == 'en'">Shop</p>
          </div>
        </div>
      </template>



       <div class="HeaderShop--main--langage">
        <em 
        class="HeaderShop--main--langage--fr" 
        :class="$route.params.language == 'fr' ? 'active':''"
        @click="setLang('fr')">
        fr</em> / 
        <em 
        class="HeaderShop--main--langage--en" 
        :class="$route.params.language == 'en' ? 'active':''"
        @click="setLang('en')">
        en</em>
       </div>

    </div>


<!--     <RedirectShop 
    v-if="displayRedirect"
    class="HeaderShop--redirect" 
    :class="[titleisHiding ? 'isHiding':'']"
    :productNumber="productNumber"
    :uid="uid"
    :content="contentRedirect">
    </RedirectShop> -->

  </div>
</template>

<script>
// import RedirectShop from '@/components/boutique/headershop/RedirectShop.vue'
import CSSvariables from '@/css/variables.scss'

export default {
  name: 'HeaderShop',
  props: ['content', 'contentRedirect', 'displayRedirect', 'productNumber', 'uid', 'context'],
  components: {
    // RedirectShop,
  },
  data: function(){
    return{
      tabScrollY : [],
      titleisHiding: false,
      margeSrollTop: '',
      scrollTop: 0,

      titleIsActive: false,
      subtitleIsActive: false
    }
  },
  watch: {
    titleisHiding: function(val){
      // console.log('titleisHiding', val)
      this.$redirectShopStore.setTitleisHiding(val)
    },
    scrollTop: function(oldScrollTop, newScrollTop){
      // console.log(oldScrollTop, newScrollTop)
      this.scrollCapture(oldScrollTop, newScrollTop)
    }
  },
  computed:{

  },
  methods: {

    setLang: function(lang){
      if (lang == 'en' && this.$route.params.language == 'fr'){
        document.location = document.location.href.replace('/fr/', '/en/')
      }

      if (lang == 'fr' && this.$route.params.language == 'en'){
        document.location = document.location.href.replace('/en/', '/fr/')
      }
    },

    mouseEnter: function(where){

      // console.log(where)

      let snipcartToggle = document.querySelector('#snipcart-toggle')

      if (where == 'title' && this.context == 'product'){
        if (snipcartToggle) {document.querySelector('#snipcart-toggle').classList.add('isHiding')}
        this.titleIsActive = true
      }

      if (where == 'subtitle'){
        if (snipcartToggle) {document.querySelector('#snipcart-toggle').classList.add('isHiding')}
        this.subtitleIsActive = true
      }  

    },

    mouseLeave: function(where){

      let snipcartToggle = document.querySelector('#snipcart-toggle')

      if (where == 'title' && this.context == 'product'){
        if (snipcartToggle) {document.querySelector('#snipcart-toggle').classList.remove('isHiding')}
        this.titleIsActive = false
      }

      if (where == 'subtitle'){
        if (snipcartToggle) {document.querySelector('#snipcart-toggle').classList.remove('isHiding')}
        this.subtitleIsActive = false
      }  

    },

    setScrollTopLandscape(){
      // console.log('setScrollTopLandscape')
      this.scrollTop = window.scrollY
    },

    scrollCapture(oldScrollTop, newScrollTop){
      // console.log(newScrollTop, this.margeSrollTop)
      if ( newScrollTop > this.margeSrollTop ){

        if (this.tabScrollY.length > 5){

          const reducer = (accumulator, currentValue) => accumulator + currentValue;

          if (this.tabScrollY.reduce(reducer) > 0 ){ // to bottom
            this.tabScrollY.splice(0);
            this.titleisHiding = true
            this.tabScrollY = []
          }else{                                    // to top
            this.tabScrollY.splice(0);
            this.titleisHiding = false
            this.tabScrollY = []
          }

          this.tabScrollY.push(oldScrollTop - newScrollTop)
        }
        this.tabScrollY.push(oldScrollTop - newScrollTop)
      }

      if ( newScrollTop < this.margeSrollTop ){
        this.titleisHiding = false
        this.tabScrollY = []
      }

      // console.log(this.tabScrollY)
    },

    setMargeScrollTop(){
      CSSvariables
      // let headerTitleHeight =  Number(CSSvariables.headertitleheight.split('vw')[0])
      // let headerFilterHeight = Number(CSSvariables.headerfilterheight.split('vw')[0])
      // let headerFilterMarge = Number(CSSvariables.headerfiltermarge.split('vw')[0])
      // let headerHeight = headerTitleHeight + headerFilterHeight + headerFilterMarge
      // let margeScrollTop = headerHeight * window.innerWidth / 100
      
      // this.margeSrollTop = margeScrollTop


      this.margeSrollTop = 80
    },

  },
  created(){
    // console.log(this.context)
    
  },
  mounted(){
    window.addEventListener('scroll', this.setScrollTopLandscape )
    this.setMargeScrollTop()

  }
  
}
</script>

<style lang="scss">
@import '@/css/variables';

.HeaderShop{
	
  width: 100%;
  position: fixed;
  z-index: 200;

  &--main{
    position: absolute;
    width: 100%;
    top: 0;


    display: flex;
    justify-content: space-between;
    height: var(--shop-menu-header-height);

    font-size: var(--shop-menu-header-font);
    background-color: black;
    color: white;


    z-index: 202;
    
    &--title{
      width: 60%;
      margin: 0.2em;
      text-align: center;
      white-space: nowrap;

      cursor: auto;

      // background-color: red;
      margin-right: 0em;
      margin-left: 0em;

      & a {
        color: white;
      }
    }
    &--langage{

      width: 20%;
      margin: 0.2em;
      text-align: right;

      font-size: 16px;
      margin-top: 13px;
      margin-right: 13px;
    }
    &--subtitle{
      width: 20%;
      margin: 0.2em;
      white-space: nowrap;

      margin-right: 0em;
      // background-color: green;

      & a {
        color: white;
      }
    }
  }

  &--redirect{
    position:absolute;
    width: 100%;
    top: var(--shop-menu-header-height);
    height: var(--shop-menu-redirect-height); // variable.css en pixel ?

    // transform: translate(0, -50%);

    z-index: 201;
    transition: transform 0.3s;


    &.isHiding{
      transform: translate(0, -100%);
    }

  }
}

.HeaderShop--main--langage--fr{
  cursor: pointer;

  &.active{
    text-decoration: underline;
  }
}
.HeaderShop--main--langage--en{
  cursor: pointer;
  &.active{
    text-decoration: underline;
  }
}






</style>