<template>
  <div class="HeaderShopMobile">

    <div class="HeaderShopMobile--main">
      
      <div class="HeaderShopMobile--main--title">
        <!-- <p>Boutique</p> -->
        <router-link v-bind:to=" '/' + $route.params.language + '/boutique'">
          <p v-if="$route.params.language == 'fr'">Boutique</p>
          <p v-if="$route.params.language == 'en'">Shop</p>
        </router-link>

      </div>

    </div>

  </div>
</template>

<script>
export default {
  name: 'HeaderShopMobile',
  props: ['content'],
  computed:{

  },
  methods: {

  }
  
}
</script>

<style lang="scss">
@import '@/css/variables';

.HeaderShopMobile{
  width: 100%;
  position: fixed;
  z-index: 9;

  &--main{
    position: absolute;
    width: 100%;

    font-size: var(--menu-header-font);
    background-color: black;

    height: var(--menu-header-height);

    color: white;
    z-index: 202;

    top: var(--menu-header-height);

    &--title{

      width: 50%;

      margin: 0.2em;
      white-space: nowrap;

      a {
        color: white;
      }
    }
  }
}





</style>