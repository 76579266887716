<template>
  <div class="NewsletterAnimate">

    <div class="NewsletterAnimate--annonce" v-if="!isClicked" @click="setClick(true)">
      
      <template v-if="ClientWidth != 'mobileWidth'">
      <p v-for="(element, index) in 4" :key="index">{{whatContentLang}}</p>
      </template>
      <template v-else>
        <p v-for="(element, index) in 2" :key="index">{{whatContentLang}}</p>
      </template>
    </div>


    <div class="NewsletterAnimate--Newsletter" v-if="isClicked">
      
      <Newsletter class="NewsletterAnimate--Newsletter--form" context="shop-animate"></Newsletter>

      <div class="NewsletterAnimate--Newsletter--quit" @click="setClick(false)">
        <img class="NewsletterAnimate--Newsletter--quit--img" src="@/assets/icone/exit.svg">
      </div>
      
    </div>


  </div>
</template>

<script>
import Newsletter from '@/components/Newsletter.vue'

import clientConfigs from '@/mixins/clientConfigs.js'

export default {
  name: 'NewsletterAnimate',
  mixins: [clientConfigs],
  props: ['content'],
  components:{
    Newsletter,
  },
  data: function(){
    return{
      isClicked: false
    }
  },
  computed:{

    whatContentLang: function(){
      if (this.$route.params.language == 'fr'){
        return 'Inscrivez-vous à la newsletter'
      }else {
        return 'Subscribe to the newsletter'
      }
    }

  },
  methods: {
    setClick: function(val){
      this.isClicked = val
    }

  }
  
}
</script>

<style lang="scss">
@import '@/css/variables';


.NewsletterAnimate{
  width: 100%;
  height: 100%;

  overflow: hidden;

  cursor: pointer;
  

  &--annonce{
    // width: 200%;
    width: 250%;
    position: absolute;
    font-size: var(--shop-menu-header-font);
    // transform: translate3d(0, 0, 0);
    background-color: white;
    // animation: slideshow 20s linear infinite;
    animation: slideshow-newsletter 20s linear infinite;

    display: flex;
    wrap: nowrap;

    height: 100%;
    align-items: center;

    & p {
      white-space: nowrap;
      padding-left: var(--esp-big);
      width: 100%;
    }

  }

  &--Newsletter{

    width: 100vw;
    height: var(--shop-menu-header-height);
    display: flex;

    &--form{
      overflow: hidden;

      width: calc(100% - var(--shop-menu-header-height));
      height: var(--shop-menu-header-height);
      font-size: var(--shop-menu-header-font);
    }

    &--quit{
      position: relative;
      right: 0;
      width: var(--shop-menu-header-height);
      // background-color: white;
      background-color: var(--shop-bg-color); 
      z-index: 10; 
      // background-image: url(../assets/icone/exit3.svg);
      // background-repeat: no-repeat;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      
      &--img{
        max-height: 80%;
        max-width: 80%;
      }
    }

    // width: 100%;
    // height: var(--shop-menu-header-height);
    // font-size: var(--menu-header-font);
    // background-color: purple;   
  }

}
  


@keyframes slideshow-newsletter {
  0%    { left: 0; }
  100%  { left: -125%; }
}



</style>